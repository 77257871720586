import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/common/Footer.scss";
import { BsFacebook, BsTwitter, BsYoutube, BsInstagram } from "react-icons/bs";
const Footer = () => {
  return (
    <Container fluid id="footer-container" className="px-0">
      <Container>
        <h1 className="notun-asha-footer-text">Notun Asha</h1>
        <Row className="pt-1 pb-5">
          <Col md={9} className="d-flex align-items-center">
            <div className="footer-category-item navbar-style">Bangladesh</div>
            <div className="footer-category-item navbar-style">
              International
            </div>
            <div className="footer-category-item navbar-style">Sports</div>
            <div className="footer-category-item navbar-style">Opinion</div>
            <div className="footer-category-item navbar-style">Business</div>
            <div className="footer-category-item navbar-style">Youth</div>
            <div className="footer-category-item navbar-style">
              Entertainment
            </div>
            <div className="footer-category-item navbar-style">Lifestyle</div>
            <div className="footer-category-item navbar-style">Education</div>
          </Col>
          <Col md={3}>
            <h3 className="footer-news-special-offer">
              For News & Special Offers
            </h3>
            <input
              type="text"
              placeholder="Enter your email ID"
              className="email-input-field"
            />
            <button className="footer-news-special-offer-submit-btn">
              Submit
            </button>
          </Col>
        </Row>
      </Container>
      <div className="hr-100"></div>
      <Container>
        <Row>
          <Col md={3} className="text-center py-3 br">
            <h3 className="footer-news-special-offer pb-3">Email Us</h3>
            <h3 className="footer-news-special-offer">Notun.asha@gmail.com</h3>
          </Col>
          <Col md={6} className="text-center py-3 br">
            <h3 className="footer-news-special-offer pb-3">Follow Us</h3>
            <div className="social-icons-section">
              <div className="social-icons-item">
                <BsFacebook />
              </div>
              <div className="social-icons-item">
                <BsTwitter />
              </div>
              <div className="social-icons-item">
                <BsYoutube />
              </div>
              <div className="social-icons-item">
                <BsInstagram />
              </div>
            </div>
          </Col>
          <Col md={3} className="text-center py-3">
            <h3 className="footer-news-special-offer pb-3">Contact Us</h3>
            <h3 className="footer-news-special-offer">02 55012201-09</h3>
          </Col>
        </Row>
      </Container>
      <div className="hr-100"></div>
      <Container>
        <div className="terms-condition-section d-flex justify-content-center">
          <h3 className="footer-terms-condition ms-4 br">Advertise</h3>
          <h3 className="footer-terms-condition ms-4 br">Terms of Use</h3>
          <h3 className="footer-terms-condition ms-4 br">Privacy Policy</h3>
        </div>
      </Container>
      <div className="hr-100"></div>
      <p className="copy-right-text">
        Copyright©2022 Notun Asa. All Rights Reserved.
      </p>
    </Container>
  );
};

export default Footer;
