import React from "react";
import "../../styles/common/Header.scss";
import { Container, Nav, Navbar } from "react-bootstrap";
import { BiBell } from "react-icons/bi";
import HeaderCategory from "./HeaderCategory";
const Header = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <p className="date">Wednesday, 09 November 2022</p>
            <Navbar.Brand
              href="#home"
              className="mx-auto notun-asha-brand-text"
            >
              Notun asha
            </Navbar.Brand>
            <Nav>
              <Nav.Link href="#notification">
                <span className="notification-icon">
                  <BiBell />
                </span>
              </Nav.Link>
              <Nav.Link eventKey={2} href="#login">
                <button className="login-btn">Login</button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <HeaderCategory />
      <div className="hr-100"></div>
    </>
  );
};

export default Header;
