import React from "react";
import "../../styles/common/HeaderCategory.scss";
import { AiOutlineSearch } from "react-icons/ai";
const HeaderCategory = () => {
  return (
    <div className="header-category-section container">
      <div className="navbar-search">
        <span className="search-icon">
          <AiOutlineSearch />
        </span>
        <div>Search</div>
      </div>
      <div className="header-category-item navbar-style">Bangladesh</div>
      <div className="header-category-item navbar-style">International</div>
      <div className="header-category-item navbar-style">Sports</div>
      <div className="header-category-item navbar-style">Opinion</div>
      <div className="header-category-item navbar-style">Business</div>
      <div className="header-category-item navbar-style">Youth</div>
      <div className="header-category-item navbar-style">Entertainment</div>
      <div className="header-category-item navbar-style">Lifestyle</div>
      <div className="header-category-item navbar-style">Education</div>
      <div className="header-category-item navbar-style">Tech</div>
      <button className="language-btn">bangla</button>
    </div>
  );
};

export default HeaderCategory;
