import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../styles/LandingPage/FeaturesSlider.scss";
// import required modules
import { Pagination, Navigation } from "swiper";
import { Card, Col, Container, Row } from "react-bootstrap";
import sliderImg1 from "../assets/images/08.jpg";
import sliderImg2 from "../assets/images/12.jpg";
const FeaturesSlider = () => {
  return (
    <Container fluid className="features-slider-container">
      <Swiper
        cssMode={true}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper container"
        style={{
          "--swiper-pagination-color": "#000000",
          "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-pagination-bullet-horizontal-gap": "6px",
        }}
      >
        <SwiperSlide>
          <Row>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4 g-0">
                <Card.Img src={sliderImg1} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4">
                <Card.Img src={sliderImg1} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4">
                <Card.Img src={sliderImg1} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4">
                <Card.Img src={sliderImg1} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4">
                <Card.Img src={sliderImg2} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4">
                <Card.Img src={sliderImg2} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4">
                <Card.Img src={sliderImg2} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ width: "18rem" }} className="px-4 py-4">
                <Card.Img src={sliderImg2} alt="" loading="lazy" />
                <Card.Body>
                  <Card.Title className="features-news-title">
                    SOCIAL MEDIA
                  </Card.Title>
                  <hr className="hr-25" />
                  <Card.Text className="features-news-description">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default FeaturesSlider;
